@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: "Poppins"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main {
  box-shadow: 0 10px 60px 18px rgba(0, 0, 0, 0.05);
}

.webName {
  background: -webkit-linear-gradient(#01eeff, #0077ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Container styling */
.scroll-container {
  overflow-y: scroll;
  box-sizing: content-box; /* Ensure padding doesn't affect the scrollbar */
}

/* Scrollbar track (background of the scrollbar) */
.scroll-container::-webkit-scrollbar {
  width: 16px; /* Width of the entire scrollbar */
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: rgb(240, 239, 239);
  border-radius: 6px;
  width: 8px !important;
}

.scroll-container::-webkit-scrollbar-track {
  background-color: black; /* Color of the scrollbar track */
  border-radius: 6px; /* Optional: Round the corners of the track */
  margin: 2px; /* Adds space around the track */
}

/* Splash.css */
@keyframes grow {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animated-element {
  animation: grow 3s ease-in-out forwards;
}

/* .datatable {
  height: 100%;
  width: 100%;
} */



.zoom-100 {
  transform: scale(4);
}

.zoom-75 {
  transform: scale(0.75);
}

.zoom-50 {
  transform: scale(0.5);
}

.zoom-25 {
  transform: scale(0.25);
}

.zoomable {
  transition: transform 0.5s ease-in;
  transform-origin: 0 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}